import { Link } from "gatsby"
import React from "react";
import { makeStyles } from '@material-ui/styles';
import {
  Close
} from '@material-ui/icons';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const useStyles = makeStyles((theme) => ({
  logo: {
    // animation: '$fadeIn ease 3s',
    // WebkitAnimation: '$fadeIn ease 3s',
    // MozAnimation: '$fadeIn ease 3s',
    // OAnimation: '$fadeIn ease 3s',
    // MsAnimation: '$fadeIn ease 3s',
    width: 168,
    [theme.breakpoints.down('sm')]: {
      width: 115
    }
  },
  // "@keyframes fadeIn": {
  //   "0%": { opacity: 0 },
  //   "100%": { opacity: 1 }
  // },
  // "@MozKeyframes fadeIn": {
  //   "0%": { opacity: 0 },
  //   "100%": { opacity: 1 }
  // },
  // "@WebkitKeyframes fadeIn": {
  //   "0%": { opacity: 0 },
  //   "100%": { opacity: 1 }
  // },
  // "@OKeyframes fadeIn": {
  //   "0%": { opacity: 0 },
  //   "100%": { opacity: 1 }
  // },
  // "@MsKeyframes fadeIn": {
  //   "0%": { opacity: 0 },
  //   "100%": { opacity: 1 }
  // },

}))

const SiteLogo = ({headerType = 'asphalt'}) => {
  const classes = useStyles();
  const siteLogo = useStaticQuery(graphql`
    query SiteHeaderQuery {
      logo: file(relativePath: { eq: "layout/asphaltanimals_logo_white.png" }) {
        childImageSharp {
          fluid (maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  if (headerType === 'mulch') {
    return (
      <Link
        to="/mulch/"
        aria-label='Home'
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
      >
        <div className={classes.logo}>
          <img alt="site-home" loading='eager' src={"https://cdn.sanity.io/images/qdh8i2f2/production/36a3dfd462e5891b8160202e82e0a6799176ed11-4879x2327.svg?fit=max&w=200&h=200"} />
        </div>
      </Link>
    )
  }
  return <Link
    to="/"
    aria-label='Home'
    style={{
      color: `white`,
      textDecoration: `none`,
    }}
  >
    <div className={classes.logo}>
      <Img alt="site-home" critical loading='eager' fluid={siteLogo.logo.childImageSharp.fluid} />
    </div>
  </Link>;
}

export default SiteLogo;
