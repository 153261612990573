import React from "react";
import { makeStyles } from '@material-ui/styles';
import {Hidden} from '@material-ui/core';
import SiteLogo from './layout/header/siteLogo';
import TopNav from './layout/header/topNav';
import MobileMenu from './layout/header/mobileNav';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 75,
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1180,
    margin: 'auto'
  },
}))

const Header = ({ headerType }) => {
  const classes = useStyles();
  return (
    <>
      <header
        className={classes.root}
      >
        <div className={classes.header}>
        <SiteLogo headerType={headerType} />
        <Hidden mdDown>
          <TopNav headerType={headerType} />
        </Hidden>
        <Hidden lgUp>
          <MobileMenu headerType={headerType} />
        </Hidden>
      </div>
      </header>
    </>

  )
}

export default Header
