/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby";
import Helmet from 'react-helmet';
import {
  ThemeProvider, makeStyles
} from '@material-ui/styles';

import theme from '../themes/baseTheme';

import Header from "./header";
import Footer from './layout/footer';
import "./layout.css";

const useStyles = makeStyles({
  siteContent: {
    // marginTop: `calc(-75px - ${theme.spacing() * 2}px)`,
    position: 'absolute',
    top: 0,
    width: '100%',
    maxWidth: '100vw',
    overflowX: 'hidden',
    background: '#edeeef'
  }
})

interface ILayoutProps {
  children?: any
}

const Layout = ({ children, headerType }: ILayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        {process.env.NODE_ENV === 'production' && <script type="text/javascript">
          {`!function(){function t(){var t=r("utm_content");if(t){var e=new Date;e.setDate(e.getDate()+30),document.cookie=t+";expires="+e.toGMTString()+";path=/"}else if(document.cookie)for(var o=document.cookie.split(/; */),n=0;n<o.length;n++)if(0===o[n].toLowerCase().trim().indexOf("utm_content=")){t=o[n];break}return t}function e(t){try{console.log(t)}catch(e){alert(t)}}function r(t){var e=top.location.search?top.location.search.substring(1):null;if(e)for(var r=e.split("&"),o=0;o<r.length;o++)if(0===r[o].toLowerCase().trim().indexOf(t+"="))return r[o];return null}var o="",n=r("mctest");if(n)e("dnr tag version: 20160125"),o="http://localhost:8080/rip/library/dnr/mcDnrTag.debug.js";else{var a=t(),c="";a&&(c=top.location.search?0<=top.location.search.indexOf("utm_content")?top.location.search:top.location.search+"&"+a:"?"+a,o="https://script.advertiserreports.com/redirector/dnr"+c)}if(o){var i=document.createElement("script");i.src=o,i.type="text/javascript",scriptTag=document.getElementsByTagName("script")[0],scriptTag.parentNode.appendChild(i)}}();
          `}
        </script>}
      </Helmet>
      <ThemeProvider theme={theme}>
        <div style={{position: 'relative'}}>
          <div style={{zIndex: 10, position: 'absolute', top: 0, width: '100%'}}>
            <Header headerType={headerType} siteTitle={data.site.siteMetadata.title} />
          </div>
          <div className={classes.siteContent}>
            <main style={{ position: 'relative' }}>{children}</main>
            <Footer />
          </div>
        </div>

      </ThemeProvider>
    </>
  )
}

export default Layout
