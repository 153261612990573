import { Link } from "gatsby"
import { keys } from 'ramda';
import React from "react";
import { assoc } from 'ramda';
import { pipe } from 'fp-ts/lib/pipeable';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import {
  Hidden, Fab, Typography, CircularProgress, Drawer,
  IconButton, Menu, MenuItem, Tooltip, List, ListItem, ListItemText
} from '@material-ui/core';
import {
  Facebook, Instagram
} from '@material-ui/icons';
import { useStaticQuery, graphql } from "gatsby";

import theme from '../../../themes/baseTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  cta: {
    fontWeight: 800,
    padding: theme.spacing(1.5)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    // animation: '$fadeIn ease 3s',
    // WebkitAnimation: '$fadeIn ease 3s',
    // MozAnimation: '$fadeIn ease 3s',
    // OAnimation: '$fadeIn ease 3s',
    // MsAnimation: '$fadeIn ease 3s',
    width: 115
  },

  menuFab: {
    fontWeight: 'bold',
    textTransform: 'none',
    position: 'fixed',
    top: theme.spacing(),
    right: theme.spacing()
  },
  scrollProgress: {
    position: 'fixed',
    top: theme.spacing() - 3,
    right: theme.spacing() - 3,
  },
  scrollProgressBar: {
    color: 'white'
  },
  menuRoot: {
    width: '100vw',
  },
  menuHeader: {
    padding: theme.spacing(),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  menuPaper: {
    background: theme.palette.background.default
  },
  tooltip: {
    borderRadius: 0,
    marginTop: 0,
    padding: 0,
    background: theme.palette.background.default
  },
  menuItem: {
    color: theme.palette.text.primary,
    fontSize: '0.8rem'
  },
  navItemTitle: {
    fontWeight: 'bold' as 'bold',
    textDecoration: 'underline',
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(2),
    fontSize: '0.8rem'
  },
  companyTitle: {
    fontWeight: 'bold' as 'bold',
    textDecoration: 'underline',
    color: theme.palette.text.primary,
    fontSize: '0.8rem'
  },
  column: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      // background: 'pink'
    }
  },
  columnInfo: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      // background: 'pink'
    }
  },
  socialIcons: {
    display: 'flex',
  },
  socialIcon: {
    margin: theme.spacing(),
    fill: theme.palette.text.primary
  },

}));

const TopNav = () => {
  const classes = useStyles();
  const initMenu = {
    anchorEl: null,
    options: []
  };
  const [menu, setMenu] = React.useState(initMenu);
  const onClose = () => setMenu(initMenu);
  const siteNav = useStaticQuery(graphql`
    query FooterNavQuery {
      footerNav: sanityNavigation(sanityId: {eq: "footer-nav-links"}) {
        title
        navItems {
          links {
            title
            _key
            target {
              ... on SanityAboutPage {
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                slug {
                  current
                }
              }
              ... on SanityFaq {
                id
                slug {
                  current
                }
              }
              ... on SanityCustomerPage {
                id
                slug {
                  current
                }
              }
              ... on SanityContact {
                id
                slug {
                  current
                }
              }
              ... on SanityJobApplication {
                id
                slug {
                  current
                }
              }
            }
          }
          title
          _key
          target {
            ... on SanityAboutPage {
              id
              slug {
                current
              }
            }
            ... on SanityService {
              id
              slug {
                current
              }
            }
            ... on SanityFaq {
              id
              slug {
                current
              }
            }
            ... on SanityCustomerPage {
              id
              slug {
                current
              }
            }
            ... on SanityContact {
              id
              slug {
                current
              }
            }
            ... on SanityJobApplication {
              id
              slug {
                current
              }
            }
          }
        }
        sanityId
      }
      sanityCompanyInfo {
        facebookUrl
        instagramUrl
        twitterUrl
        title
        streetAddress
        state
        city
        email
        phone
        companyLogo {
          alt
        }
      }
    }
  `);
  const {
    sanityCompanyInfo: {
      title = null,
      streetAddress = null,
      city = null,
      state = null,
      email = null,
      phone = null,
      facebookUrl: facebook = null,
      instagramUrl: instagram = null
    }
  } = siteNav;
  const companyInfo = <div className={classes.columnInfo}>
    <img src="https://cdn.sanity.io/images/qdh8i2f2/production/702e9bc912b4d55dbc8bcad68a8caea0f91bba2e-481x289.png" height="100px" alt="asphalt-animals-logo" />
    <Typography className={classes.companyTitle}>{title}</Typography>
    <Typography className={classes.menuItem}>{email}</Typography>
    <Typography className={classes.menuItem}>{phone}</Typography>
    <Typography className={classes.menuItem}>{streetAddress}</Typography>
    {city && state && <Typography className={classes.menuItem}>{city}{`, `}{state}</Typography>}
  </div>;
  const socialInfo = <div className={classes.columnInfo}>
    <Typography className={classes.companyTitle}>Follow Us</Typography>
    <div className={classes.socialIcons}>
      {facebook && <a target='_blank' href={facebook}><Facebook className={classes.socialIcon} /></a>}
      {instagram && <a target='_blank' href={instagram}><Instagram className={classes.socialIcon} /></a>}
    </div>
  </div>;
  return <ThemeProvider theme={theme}>
      <div className={classes.root}>
        {companyInfo}
        {socialInfo}
        {siteNav?.footerNav?.navItems.map(link => {
          return (
            <div className={classes.column}>
              <Typography className={classes.navItemTitle}>{link.title}</Typography>
              <List dense>{link.links.map(item =>
                <div key={item._key}><Link
                  style={{ textDecoration: 'none' }}

                  to={item.target?.slug?.current && `/${item.target?.slug?.current}/`}>
                  <ListItem button disabled={!item.target?.slug?.current}><ListItemText className={classes.menuItem}>{item.title}</ListItemText></ListItem>
                </Link></div>)}</List>
            </div>)
        })}

        {/*<Button variant='contained' color='secondary' className={classes.cta}>Free Estimate</Button>*/}
      </div>
    </ThemeProvider>
}

export default TopNav;
