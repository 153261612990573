/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby";
import Helmet from 'react-helmet';
import {
  Typography
} from '@material-ui/core';
import {
  ThemeProvider, makeStyles
} from '@material-ui/styles';
import FooterNav from './footer/footerNav';



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "rgba(0,0,0,1)",
    background: 'url(https://cdn.sanity.io/images/qdh8i2f2/production/ffb765937771bb32466d8f81153247ece0522b50-1920x677.png)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.text.primary
  },
  siteContent: {
    // marginTop: `calc(-75px - ${theme.spacing() * 2}px)`,
    position: 'absolute',
    top: 0,
    width: '100%'
  }
}))

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query FooterQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);
  const classes = useStyles();
  return (
      <footer className={classes.root}>
        <FooterNav />
        <Typography align='center' className={classes.text}>

        © {new Date().getFullYear()}, C & R Asphalt LLC.
        </Typography>

      </footer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
