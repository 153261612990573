import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import { assoc } from 'ramda';
import {pipe} from 'fp-ts/lib/pipeable';
import {
    yellow,
    red, grey
} from '@material-ui/core/colors';

export const baseTheme = {
    palette: {
        type: 'dark',
        primary: assoc(500, 'rgb(237, 28, 36)', red),
        secondary: pipe(grey,
          g => assoc(500, '#fff', g),
          g => assoc('main', '#fff', g)
        ),
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            background: 'pink',
            boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
          }
        }
      }
    },
    overrides: {
        MuiOutlinedInput: {
          root: {
            borderRadius: 0
          }
        },
        MuiTypography: {
          h1: {
            fontSize: '3.75rem',
          },
          h2: {
            fontSize: '1.5rem',
            fontWeight: 500
          }
        },
        MuiDialog: {
          root: {
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(5px)',
          }
        },
        MuiMenuItem: {
          root: {
            fontFamily: 'Outfit'
          }
        },
        MuiListItemText: {
          root: {
            fontFamily: 'Outfit'
          }
        },
        MuiButton: {
            root: {
              minWidth: 30,
              fontFamily: 'Outfit',
              borderRadius: '12px',
            },
            text: {
              textTransform: 'none',
              borderRadius: 0
            },
            contained: {
              textTransform: 'none',
              backgroundColor: "#424242",
              color: 'white',
              borderRadius: '12px',
              padding: '14px 30px',
            },
            containedPrimary: {
                color: 'white',
                textTransform: 'none',
                border: '2px solid transparent',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#242E49',
                  border: '2px solid rgb(237, 28, 36)',
                }

            },
            containedSecondary: {
                color: 'white',
                backgroundColor: 'rgba(12, 10, 21, 0.35)',
                border: '2px solid rgb(237, 28, 36)',
                textTransform: 'none',
                // borderRadius: 0,
                fill: 'white',
                stroke: '#242E49',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#242E49',
                  fill: '#242E49',
                  stroke: 'white'
                }
            },
            outlined: {
              borderRadius: '18px',
              padding: '18px 32px',
              border: '2px solid #242E49',
              textTransform: 'none',
              color: 'rgb(237, 28, 36)',
              '&:hover': {
                color: 'white',
                background: '#242E49',
                border: '2px solid white',
              }
            },
            custom: {
              borderRadius: 0,
              border: '1px solid pink',
              textTransform: 'none'
            }
        }
    },
    typography: {
        fontFamily: [
            'Outfit',
            'Montserrat',
            'sans-serif'
        ].join(','),
    },
}

const theme = createMuiTheme(baseTheme);
// export const themeV2 = createTheme({
//   palette: {
//     mode: 'light'
//   },
//   components: {
//     MuiPaper: {
//       styleOverrides: {
//         elevation1: {
//           boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
//         }
//       }
//     }
//   },
// });

export default theme;
