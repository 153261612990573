import { Link, navigate } from "gatsby"
import { keys } from 'ramda';
import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { assoc, append } from 'ramda';
import { pipe } from 'fp-ts/lib/pipeable';
import { makeStyles } from '@material-ui/styles';
import {
  Button, Fab, Typography, CircularProgress, Drawer,
  IconButton, List, ListItem, ListItemText, Collapse
} from '@material-ui/core';
import {
  Close,
  ArrowForwardIos as CarrotRight
} from '@material-ui/icons';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import SiteLogo from './siteLogo';
import {
  update,
} from '../../../state/project/ducks';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 75,
    padding: theme.spacing(),
  },
  cta: {
    fontWeight: 800,
    padding: theme.spacing(1.5)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    // animation: '$fadeIn ease 3s',
    // WebkitAnimation: '$fadeIn ease 3s',
    // MozAnimation: '$fadeIn ease 3s',
    // OAnimation: '$fadeIn ease 3s',
    // MsAnimation: '$fadeIn ease 3s',
    width: 115
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 }
  },
  "@MozKeyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 }
  },
  "@WebkitKeyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 }
  },
  "@OKeyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 }
  },
  "@MsKeyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 }
  },
  menuFab: {
    fontWeight: 'bold',
    textTransform: 'none',
    position: 'fixed',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 100
  },
  scrollProgress: {
    position: 'fixed',
    top: theme.spacing(2) - 3,
    right: theme.spacing(2) - 3,
  },
  scrollProgressBar: {
    color: 'white'
  },
  menuRoot: {
    width: '100vw',
  },
  menuHeader: {
    padding: theme.spacing(),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  menuPaper: {
    background: theme.palette.background.default
  },
  tooltip: {
    borderRadius: 0,
    marginTop: 0,
    padding: 0,
    background: theme.palette.background.default
  },
  menuItem: {
    color: theme.palette.text.primary
  },
  subLink: {
    paddingLeft: theme.spacing(4)
  },
  listText: {
    color: theme.palette.text.primary
  },
  collapsed: {
    transition: 'transform 300ms'
  },
  unCollapse: {
    transform: 'rotate(0.25turn)',
    transition: 'transform 300ms'
  }

}));

const MobileMenuComponent = ({update}) => {
  const classes = useStyles();

  const siteNav = useStaticQuery(graphql`
    query SiteMobileNavQuery {
      mobileNav: sanityNavigation(sanityId: {eq: "mobile-menu-nav"}) {
        title
        navItems {
          links {
            title
            _key
            target {
              ... on SanityAboutPage {
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                slug {
                  current
                }
              }
              ... on SanityFaq {
                id
                slug {
                  current
                }
              }
              ... on SanityCustomerPage {
                id
                slug {
                  current
                }
              }
              ... on SanityContact {
                id
                slug {
                  current
                }
              }
              ... on SanityJobApplication {
                id
                slug {
                  current
                }
              }
            }
          }
          title
          _key
          target {
            ... on SanityAboutPage {
              id
              slug {
                current
              }
            }
            ... on SanityService {
              id
              slug {
                current
              }
            }
            ... on SanityFaq {
              id
              slug {
                current
              }
            }
            ... on SanityCustomerPage {
              id
              slug {
                current
              }
            }
            ... on SanityContact {
              id
              slug {
                current
              }
            }
            ... on SanityJobApplication {
              id
              slug {
                current
              }
            }
          }
        }
        sanityId
      }
    }
  `);
  const [scroll, setScroll] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [navItems, setNavItems] = React.useState([]);
  const openProject = () => {
    setOpen(false);
    update(['form', 'currentQuestion'], 'projectType');
  };
  React.useEffect(() => {
    let progressBarHandler = () => {

      const totalScroll = document.documentElement.scrollTop || window.scrollY;
      const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scroll = `${totalScroll / windowHeight}`;
      setScroll(parseFloat(scroll) * 100);
    }

    window.addEventListener("scroll", progressBarHandler);

    return () => window.removeEventListener("scroll", progressBarHandler);
  }, []);
  return <>
    <CircularProgress
      thickness={3}
      size={62}
      variant='determinate'
      value={scroll}
      color='secondary'
      className={classes.scrollProgress}
      classes={{
        colorSecondary: classes.scrollProgressBar
      }} />
    <Fab
      className={classes.menuFab}
      onClick={() => setOpen(true)}
      aria-label="menu"
      color='primary'>
      <Typography variant='caption'>Menu</Typography>
    </Fab>

    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        className: classes.menuPaper
      }}
      onClose={() => setOpen(false)}>
      <div className={classes.menuRoot}>
        <div className={classes.menuHeader}>
          <SiteLogo />
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </div>
        <List>
          <Link to='/' style={{ textDecoration: 'none', flexGrow: 1 }}>
            <ListItem button >
              <ListItemText className={classes.listText}>Home</ListItemText>
            </ListItem>
          </Link>
          {siteNav?.mobileNav?.navItems.map(link => {
            return (
              <div key={link._key}>
                <ListItem
                  button
                  disabled={link.links.length === 0 && !link.target}
                  onClick={() => {
                    !navItems.includes(link._key) ? setNavItems(append(link._key, navItems)) : setNavItems(navItems.filter(i => i !== link._key))
                    link.target && navigate(`/${link.target.slug.current}/`);
                  }}>
                  <ListItemText className={classes.listText}>{link.title}</ListItemText>
                  {link.links.length > 0 && <CarrotRight className={navItems.includes(link._key) ? classes.unCollapse : classes.collapsed} fontSize='small' />}
                </ListItem>
                <Collapse in={navItems.includes(link._key)}>
                  <List dense>
                    {link.links.map(l =>
                      <Link to={l.target && `/${l.target.slug.current}/`} style={{ textDecoration: 'none', flexGrow: 1 }}>
                        <ListItem disabled={!l.target} button className={classes.subLink} key={l._key}>
                          <ListItemText className={classes.listText}>{l.title}</ListItemText>
                        </ListItem>
                      </Link>
                    )}
                  </List>
                </Collapse>
              </div>
            )
          })}
          <div style={{padding: 16}}>
            <Button onClick={openProject} fullWidth variant='contained' color='secondary' className={classes.cta}>Free Estimate</Button>
          </div>
        </List>
      </div>
    </Drawer>
  </>
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({
            update
        }, dispatch)
    };
};

const MobileMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileMenuComponent);

export default MobileMenu;
