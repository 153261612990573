import { Link } from "gatsby"
import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import {
  Button, Typography, CircularProgress, Drawer, Menu, MenuItem, Tooltip
} from '@material-ui/core';
import { useStaticQuery, graphql } from "gatsby";
import { MdArrowBack } from 'react-icons/md'


import {
  update,
} from '../../../state/project/ducks';
import { Phone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  cta: {
    fontWeight: 800,
    // padding: theme.spacing(1.5)
  },
  tooltip: {
    borderRadius: 16,
    marginTop: 0,
    padding: 0,
    background: theme.palette.background.default
  },
  menuItem: {
    color: theme.palette.text.primary
  },
  callUsButton: {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(15, 10, 19, 0.45)',
    padding: '12px 25px 12px 14px',
    borderRadius: '90px',
    stroke: 'white',
    '&:hover': {
      background: 'white',
      color: 'black',
      stroke: 'black'
    }
  }

}));

const CallUsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path d="M17.8096 16.2763C17.8096 16.5763 17.743 16.8846 17.6013 17.1846C17.4596 17.4846 17.2763 17.768 17.0346 18.0346C16.6263 18.4846 16.1763 18.8096 15.668 19.018C15.168 19.2263 14.6263 19.3346 14.043 19.3346C13.193 19.3346 12.2846 19.1346 11.3263 18.7263C10.368 18.318 9.40964 17.768 8.45964 17.0763C7.5013 16.3763 6.59297 15.6013 5.7263 14.743C4.86797 13.8763 4.09297 12.968 3.4013 12.018C2.71797 11.068 2.16797 10.118 1.76797 9.1763C1.36797 8.2263 1.16797 7.31797 1.16797 6.4513C1.16797 5.88464 1.26797 5.34297 1.46797 4.84297C1.66797 4.33464 1.98464 3.86797 2.4263 3.4513C2.95964 2.9263 3.54297 2.66797 4.15964 2.66797C4.39297 2.66797 4.6263 2.71797 4.83464 2.81797C5.0513 2.91797 5.24297 3.06797 5.39297 3.28464L7.3263 6.00964C7.4763 6.21797 7.58464 6.40964 7.65964 6.59297C7.73464 6.76797 7.7763 6.94297 7.7763 7.1013C7.7763 7.3013 7.71797 7.5013 7.6013 7.69297C7.49297 7.88464 7.33464 8.08464 7.13464 8.28464L6.5013 8.94297C6.40964 9.03464 6.36797 9.14297 6.36797 9.2763C6.36797 9.34297 6.3763 9.4013 6.39297 9.46797C6.41797 9.53464 6.44297 9.58464 6.45964 9.63464C6.60964 9.90964 6.86797 10.268 7.23464 10.7013C7.60964 11.1346 8.00964 11.5763 8.44297 12.018C8.89297 12.4596 9.3263 12.868 9.76797 13.243C10.2013 13.6096 10.5596 13.8596 10.843 14.0096C10.8846 14.0263 10.9346 14.0513 10.993 14.0763C11.0596 14.1013 11.1263 14.1096 11.2013 14.1096C11.343 14.1096 11.4513 14.0596 11.543 13.968L12.1763 13.343C12.3846 13.1346 12.5846 12.9763 12.7763 12.8763C12.968 12.7596 13.1596 12.7013 13.368 12.7013C13.5263 12.7013 13.693 12.7346 13.8763 12.8096C14.0596 12.8846 14.2513 12.993 14.4596 13.1346L17.218 15.093C17.4346 15.243 17.5846 15.418 17.6763 15.6263C17.7596 15.8346 17.8096 16.043 17.8096 16.2763Z" stroke="inherit" stroke-width="1.2" stroke-miterlimit="10"/>
        <path d="M14.9167 8.5026C14.9167 8.0026 14.525 7.23594 13.9417 6.61094C13.4083 6.03594 12.7 5.58594 12 5.58594" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.8333 8.5013C17.8333 5.2763 15.225 2.66797 12 2.66797" stroke="inherit" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
  )
}

const Dot = () => (
  <div style={{ paddingLeft: '12px', paddingRight: '12px', display: 'flex', alignItems: 'center' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="6" viewBox="0 0 5 6" fill="none">
      <circle cx="2.5" cy="3" r="2.5" fill="white" />
    </svg>
  </div>
)

const TopNavComponent = ({
  update,
  headerType = 'default'
}) => {
  const classes = useStyles();
  const openProject = () => update(['form', 'currentQuestion'], 'projectType');
  const siteNav = useStaticQuery(graphql`
    query SiteNavQuery {
      mulchDesktopNav: sanityNavigation(sanityId: {eq: "mulch-top-nav-desktop"}) {
        title
        navItems {
          title
          _key
        }
      }
      desktopNav: sanityNavigation(sanityId: {eq: "desktop-top-nav"}) {
        title
        navItems {
          links {
            title
            _key
            target {
              ... on SanityAboutPage {
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                slug {
                  current
                }
              }
              ... on SanityFaq {
                id
                slug {
                  current
                }
              }
              ... on SanityCustomerPage {
                id
                slug {
                  current
                }
              }
              ... on SanityContact {
                id
                slug {
                  current
                }
              }
              ... on SanityJobApplication {
                id
                slug {
                  current
                }
              }
            }
          }
          title
          _key
          target {
            ... on SanityAboutPage {
              id
              slug {
                current
              }
            }
            ... on SanityService {
              id
              slug {
                current
              }
            }
            ... on SanityFaq {
              id
              slug {
                current
              }
            }
            ... on SanityCustomerPage {
              id
              slug {
                current
              }
            }
            ... on SanityContact {
              id
              slug {
                current
              }
            }
            ... on SanityJobApplication {
              id
              slug {
                current
              }
            }
          }
        }
        sanityId
      }
      mobileNav: sanityNavigation(sanityId: {eq: "mobile-menu-nav"}) {
        title
        navItems {
          links {
            title
            _key
            target {
              ... on SanityAboutPage {
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                slug {
                  current
                }
              }
              ... on SanityFaq {
                id
                slug {
                  current
                }
              }
              ... on SanityCustomerPage {
                id
                slug {
                  current
                }
              }
              ... on SanityContact {
                id
                slug {
                  current
                }
              }
              ... on SanityJobApplication {
                id
                slug {
                  current
                }
              }
            }
          }
          title
          _key
          target {
            ... on SanityAboutPage {
              id
              slug {
                current
              }
            }
            ... on SanityService {
              id
              slug {
                current
              }
            }
            ... on SanityFaq {
              id
              slug {
                current
              }
            }
            ... on SanityCustomerPage {
              id
              slug {
                current
              }
            }
            ... on SanityContact {
              id
              slug {
                current
              }
            }
            ... on SanityJobApplication {
              id
              slug {
                current
              }
            }
          }
        }
        sanityId
      }
    }
  `);
  return <div style={{ display: 'flex', alignItems: 'center' }}>
    <Dot />
    {headerType === 'mulch' && siteNav?.mulchDesktopNav?.navItems.map(link => {
      return (
        <>
          <Link style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'white' }} to={`/`}>

            <Button style={{ height: '100%' }} variant='text'>
              {link.title === 'Asphalt Animals' && <MdArrowBack style={{ marginRight: 4 }} />}
              {link.title}
            </Button>
          </Link>
          <Dot />
        </>
      );
    })}
    {headerType === 'default' && siteNav?.desktopNav?.navItems.map(link => {
      if (link?.target) {
        return (
          <>
            <Link style={{ textDecoration: 'none', display: 'flex' }} to={`/${link?.target?.slug?.current}/`}>
              <Button style={{ height: '100%' }} disabled={!link.target && link.links.length === 0} variant='text'>{link.title}</Button>
            </Link>
            <Dot />
          </>

        )
      }
      return (
        <>
          <Tooltip
            key={link._key}
            interactive
            aria-label={link.title}
            classes={{
              tooltip: classes.tooltip
            }}
            placement='bottom-start'
            // arrow
            // PopperProps={{
            //   className: classes.menuTooltip
            // }}
            title={
              <React.Fragment>
                {link.links.map(item =>
                  <div key={item._key}>
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/${item.target?.slug?.current}/`}>
                      <MenuItem disabled={!item.target} className={classes.menuItem} fullWidth>{item.title}</MenuItem>
                    </Link>
                  </div>
                )}
              </React.Fragment>}>
            <Button disabled={!link.target && link.links.length === 0} variant='text'>{link.title}</Button>
          </Tooltip>
          <Dot />
        </>
      )
    })}
    {/* {headerType === 'default' && <Button onClick={openProject} variant='contained' color='secondary' className={classes.cta}>Free Estimate</Button>} */}
    {headerType === 'default' && <a style={{textDecoration: 'none'}} href="tel:+18592550077">
            <Button style={{fontWeight: 800}} className={classes.callUsButton}>
              <span style={{marginRight: '0.5rem', display: 'flex', alignItems: 'center'}}>
                <CallUsIcon  />
              </span>
              {`(859) 255-0077`}
            </Button>
          </a>}
  </div >
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ...bindActionCreators({
      update
    }, dispatch)
  };
};

const TopNav = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopNavComponent);

export default TopNav;
